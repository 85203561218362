@import "../../../styles/brand-colors";

.accordion-button:after {
  order: -1;
  margin-left: 0;
  margin-right: 0.5em;
}
.accordionBodyPadding {
  padding-left: 40px !important;
}

.ruleHeader {
  font-weight: bold;
}

.smallFontSize {
  font-size: 12px;
}

.verticalLine {
  border-right: 1px solid grey;
  height: 120%;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.noPaddingLeft {
  padding-left: 0 !important;
}

.bold {
  font-weight: bold;
}

.alertMidnight {
  background-color: $midnight-4;
  color: #41464b;
}

.headerPadding {
  padding-left: 14px;
}

.iconPadding {
  padding-left: 10px;
}

.sortIcon path {
  fill: $lake;
}