@import "src/styles/brand-colors";

.borderBottom {
  border-bottom: 1px solid $midnight-40 !important;
}

.appFooter {
  margin-top: 60px;
}

.loader > svg {
  width: 35px;
  height: 35px;
}

.headerSeparator {
  padding: 0px;
  margin: 0 -32px 16px -32px;
  color: $midnight-40;
}