@import "src/styles/brand-colors";

.borderMidnight {
  border: 1px solid $midnight-40 !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $lake;
}

input:focus + .slider {
  box-shadow: 0 0 1px $lake;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switchPadding {
  padding-left: 0 !important;
}

.innerSelectMargin {
  margin-left: 50px !important;
}

.groupFormPadding {
  padding-top: 3px !important;
  padding-bottom: 6px !important;
}

.cancelButton {
  margin-right: 24px;
}

.topPanel button {
  width: 100%;
}

.unitAssignedPanel {
  border-left: solid 1px $midnight-20 !important;
}

.unitAssignedPanel a {
  color: $lake;
}

.unitAssignedPanel label {
  font-weight: 500;
}

.unitAssignedPanel hr {
  color: $lake;
}

.unitAssignedPanel p {
  color: $dusk-lightest;
  font-size: 12px;
}

.backIconContainer {
  padding-top: 8px;
}

.topLeftPanel {
  max-width: 40px !important;
}

.topLeftPanel svg:hover {
  cursor: pointer;
}

.unitList {
  color: $lake !important;
  font-size: 14px !important;
}