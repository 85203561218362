$white: #ffffff;
$black: #000000;
$gray-one: #2c3439;
$gray-two: #4c5357;
$gray-three: #99adb6;
$gray-four: #b2c2c8;
$gray-five: #ccd6db;
$gray-six: #e5ebed;
$gray-seven: #fbfbfc;
$gray-other: #f5f5f5;
$gray-lighter-other: #eeeeef;

$dusk: #2c3439;
$dusk-light: #4c5357;
$dusk-lighter: #707579;
$dusk-lightest: #969a9c;
$dusk-thirty: #bfc2c3;
$dusk-twenty: #d5d6d7;
$dusk-ten: #e9eaeb;
$dusk-four: #efefef;
$dusk-seven: #f0f1f1;
$dusk-three: #f8f9f9;
$dusk-two: #f9f9f9;
$dusk-one: #fcfdfd;

$gray: $dusk;
$gray-light: $dusk-light;
$gray-lighter: $dusk-lighter;
$gray-lightest: $dusk-lightest;

$vacasa-yorange: #ffad02;
$owner-yellow: #ffd00a;
$guest-yellow: #ffe700;
$guest-yellow-20: #fff1b6;
$guest-yellow-40: #ffe36c;

$midnight: #003349;
$midnight-100: #003349;
$midnight-90: #1a475b;
$midnight-80: #335c6d;
$midnight-70: #4d7080;
$midnight-60: #668592;
$midnight-50: #8099a4;
$midnight-40: #99adb6;
$midnight-30: #b2c2c8;
$midnight-20: #ccd6db;
$midnight-10: #e5ebed;
$midnight-4: #f5f7f8;
$midnight-2: #fbfbfc;
$midnight-0: #fafbfb;

$cabin: #783d24;
$sunset: #d62e4f;
$jewel: #631c69;
$coast: #004273;
$forest: #0f633d;
$clay: #d16333;
$coral: #ff9470;
$wine: #a3286d;
$surf: #00899e;
$moss: #6ba342;
$cafe: #ddad49;
$sand: #ffd19e;
$lavander: #d18ab2;
$caribbean: #89d8c6;
$meadow: #b2d872;

$lake: #0078ab;
$lake-two: #4889f4;

$primary: $owner-yellow;
$primary-70: mix(white, $primary, 30%);
$primary-40: mix(white, $primary, 60%);
$primary-10: mix(white, $primary, 90%);
$primary-light: mix(white, $primary, 30%);
$primary-dark: mix(black, $primary, 10%);

$secondary: $lake;
$secondary-70: mix(white, $secondary, 30%);
$secondary-40: mix(white, $secondary, 60%);
$secondary-10: mix(white, $secondary, 90%);
$secondary-light: mix(white, $secondary, 30%);
$secondary-dark: mix(black, $secondary, 10%);

$default: $lake;
$default-70: mix(white, $default, 30%);
$default-40: mix(white, $default, 60%);
$default-10: mix(white, $default, 90%);
$default-light: mix(white, $default, 30%);
$default-dark: mix(black, $default, 10%);

$info: $midnight;
$info-90: mix(white, $info, 10%);
$info-80: mix(white, $info, 20%);
$info-70: mix(white, $info, 30%);
$info-60: mix(white, $info, 40%);
$info-50: mix(white, $info, 50%);
$info-40: mix(white, $info, 60%);
$info-30: mix(white, $info, 70%);
$info-20: mix(white, $info, 80%);
$info-10: mix(white, $info, 90%);
$info-4: mix(white, $info, 96%);
$info-2: mix(white, $info, 98%);
$info-light: mix(white, $info, 30%);
$info-dark: mix(black, $info, 10%);

$success: #00a433;
$success-light: mix(white, $success, 30%);
$success-dark: mix(black, $success, 10%);
$success-70: mix(white, $success, 30%);
$success-40: mix(white, $success, 60%);
$success-10: mix(white, $success, 90%);

$danger: $sunset;
$danger-light: mix(white, $danger, 30%);
$danger-dark: mix(black, $danger, 10%);
$danger-70: mix(white, $danger, 30%);
$danger-40: mix(white, $danger, 60%);
$danger-10: mix(white, $danger, 90%);

$alertness: #bd0024;
$alertness-70: mix(white, $alertness, 30%);
$alertness-40: mix(white, $alertness, 60%);
$alertness-10: mix(white, $alertness, 90%);

$warning: $cafe;
$awereness: $cafe;
$awereness-70: mix(white, $awereness, 30%);
$awereness-40: mix(white, $awereness, 60%);
$awereness-10: mix(white, $awereness, 90%);

$shadow-color: rgba(0, 0, 0, 0.08);
