body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accordion-button:focus {
  border-color: #e5ebed !important;
  box-shadow: none !important;
}

.accordion-button:after {
  order: -1;
  margin-left: 0;
  margin-right: 0.5em;
}

.accordion-button:not(.collapsed) {
  background-color: #e5ebed !important;
  color: #000000 !important;
}

.required label:after {
  content: "*";
  color: red;
}
