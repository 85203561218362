.html, .container-custom, body {
    background-color: #E4EBED!important;
}

.page-item.active .page-link {
    background-color: #0078ab !important;
    border-color: #0078ab !important;
    color: #fff !important;
}

.page-link {
    color: #0078ab !important;
}

.page-link:hover {
   cursor: pointer;
}